<template>
  <div id="app">
    <router-view v-if="!loading" class="view" />
    <loading-screen v-else />
    <toast></toast>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import LoadingScreen from './components/LoadingScreen.vue';
import Toast from './components/Toast.vue';

export default {
  components: { LoadingScreen, Toast },

  computed: {
    ...mapState(['loading']),
  },
};
</script>

<style lang="scss" scoped>
#app {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.view {
  height: 100%;
  overflow-y: auto;
}
</style>
