import Vue from 'vue';
import Vuex from 'vuex';
import router from '../router';

import * as fb from '../firebase';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userProfile: {
      finishedSurvey: false,
    },
    company: {},
    survey: {
      id: '',
    },
    surveyForm: [],
    scale: [],
    formLength: 0,
    currentStep: 0,
    loading: false,
    madeChanges: false,
    toast: '',
    isSidebarOpen: false,
  },

  mutations: {
    setUserProfile(state, profile) {
      state.userProfile = profile;
    },

    clearState(state) {
      state.userProfile = {};
      state.company = {};
      state.survey = {};
      state.surveyForm = [];

      state.formLength = 0;
      state.currentStep = 0;
      state.madeChanges = false;
    },

    setCompany(state, company) {
      state.company = company;
    },

    setSurvey(state, survey) {
      state.survey = survey;
    },

    setLoadingState(state, value) {
      state.loading = value;
    },

    setToast(state, toast) {
      if (state.toast) {
        state.toast = '';
        setTimeout(() => { state.toast = toast; }, 1000);
      } else state.toast = toast;
    },

    setStep(state, value) {
      if (typeof value !== 'number') return;
      if (value < 0) return;
      if (value >= state.formLength) return;
      state.currentStep = value;
    },

    setAnswer(state, { index, answer }) {
      state.surveyForm[index].answer = answer;
      state.madeChanges = true;
    },

    setScale(state, scale) {
      state.scale = scale;
    },

    addQuestions(state, questions) {
      state.surveyForm.push(...questions);
    },

    setFormLength(state) {
      state.formLength = state.surveyForm.length;
    },

    progressSaved(state) {
      state.madeChanges = false;
    },

    loadProgress(state) {
      if (!state.userProfile.answers) return;

      const { answers } = state.userProfile;

      answers.forEach((a) => {
        const question = state.surveyForm.find((q) => q.question.id === a.question.id);

        if (!question) return;

        question.answer = a.answer;
      });

      if (answers.length >= state.formLength) state.currentStep = state.formLength - 1;
      else state.currentStep = answers.length;
    },

    finishSurvey(state) {
      state.userProfile.finishedSurvey = true;
    },

    closeSidebar(state) {
      state.isSidebarOpen = false;
    },

    openSidebar(state) {
      state.isSidebarOpen = true;
    },
  },

  actions: {
    async login(context, { user, password }) {
      await fb.auth.signInWithEmailAndPassword(user, password);

      router.push('/welcome');
    },

    async logout({ commit }) {
      await fb.auth.signOut();

      commit('clearState');

      router.push('/login');
    },

    async fetchInitialData({ commit, dispatch }, uid) {
      commit('setLoadingState', true);
      const userProfile = await dispatch('fetchUserProfile', uid);

      if (userProfile.admin) {
        await dispatch('logout');
        commit('setLoadingState', false);
        return;
      }

      const company = await dispatch('fetchCompany', userProfile.companyId);

      if (!company.activeSurveyId) {
        router.push('/no-survey');
        commit('setLoadingState', false);
        return;
      }

      const survey = await dispatch('fetchSurvey', company.activeSurveyId);

      if (userProfile.finishedSurvey) {
        router.push('/thank-you');
        commit('setLoadingState', false);
        return;
      }

      const endDate = new Date(`${survey.endDate}T00:00:00`.replace(/-/g, '/').replace(/T.+/, '')).toISOString().slice(0, 10);

      const today = new Date().toISOString().slice(0, 10);

      if (endDate < today) router.push('/no-survey');

      commit('setLoadingState', false);
    },

    async fetchUserProfile({ commit }, uid) {
      const userDoc = await fb.usersCollection.doc(uid).get();

      const userProfile = {
        id: userDoc.id,
        finishedSurvey: false,
        ...userDoc.data(),
      };

      commit('setUserProfile', userProfile);

      return userProfile;
    },

    async fetchCompany({ commit }, companyId) {
      const companySnap = await fb.companiesCollection.doc(companyId).get();

      const company = {
        id: companySnap.id,
        ...companySnap.data(),
      };

      commit('setCompany', company);

      return company;
    },

    async fetchSurvey({ commit }, id) {
      const doc = await fb.surveysCollection.doc(id).get();

      const survey = { id, ...doc.data() };

      commit('setSurvey', survey);

      return survey;
    },

    async fetchSurveyContent({ commit, dispatch }) {
      await dispatch('fetchQuestions');
      await dispatch('fetchScale');

      commit('setFormLength');
      commit('loadProgress');
    },

    async fetchQuestions({ state, commit }) {
      const questionSnaps = await fb.questionsCollection(state.company.activeSurveyId)
        .orderBy('index')
        .get();

      const questions = [];

      let enpsQuestion;

      questionSnaps.forEach((qs) => {
        const question = { id: qs.id, ...qs.data() };
        if (question.sectionId === 'enps') {
          enpsQuestion = question;
          return;
        }
        questions.push({
          answer: question.isOpenQuestion ? '' : {
            id: '',
            text: '',
          },
          question: {
            id: qs.id,
            text: qs.data().text,
          },
          section: qs.data().section,
        });
      });

      if (enpsQuestion) {
        questions.push({
          answer: NaN,
          question: {
            id: enpsQuestion.id,
            text: enpsQuestion.text,
          },
          section: {
            id: enpsQuestion.sectionId,
            // name: '',
          },
        });
      }

      commit('addQuestions', questions);
    },

    async fetchScale({ state, commit }) {
      const optionSnaps = await fb.scaleCollection(state.company.activeSurveyId)
        .orderBy('order')
        .get();

      const scale = [];

      optionSnaps.forEach((os) => {
        scale.push({ id: os.id, text: os.data().text });
      });

      commit('setScale', scale);
    },

    async saveProgress({ state, commit }) {
      const answeredQuestions = state.surveyForm.filter((q) => {
        if (typeof q.answer === 'object') return q.answer.id;
        return q.answer;
      });

      await fb.usersCollection
        .doc(state.userProfile.id)
        .update({
          answers: answeredQuestions,
          progress: +((answeredQuestions.length / state.formLength).toFixed(2)),
        });

      commit('progressSaved');
    },

    async pushAnswers({ state, commit }) {
      const isFormCompleted = state.surveyForm.every((q) => {
        if (typeof q.answer === 'string') return true;
        if (typeof q.answer === 'number') return q.answer >= 0;
        return q.answer;
      });

      if (!isFormCompleted) throw new Error('Form not completed');

      const batch = fb.db.batch();

      const {
        multipleChoiceAnswers,
        enpsAnswer,
        engagementAnswers,
        openAnswers,
      } = state.surveyForm.reduce(
        (obj, a) => {
          if (typeof a.answer === 'string') {
            return { ...obj, openAnswers: [...obj.openAnswers, a] };
          }
          if (a.section.id === 'engagement') {
            return { ...obj, engagementAnswers: [...obj.engagementAnswers, a] };
          }
          if (a.section.id === 'enps') {
            return { ...obj, enpsAnswer: a };
          }
          return {
            ...obj,
            multipleChoiceAnswers: [...obj.multipleChoiceAnswers, a],
          };
        },
        {
          multipleChoiceAnswers: [],
          enpsAnswer: {},
          engagementAnswers: [],
          openAnswers: [],
        },
      );

      const answerRef = fb.answersCollection(state.survey.id).doc();

      batch.set(answerRef, {
        multipleChoiceAnswers,
        enpsAnswer,
        engagementAnswers,
        openAnswers,
        features: state.userProfile.filters,
        userId: state.userProfile.id,
        createdAt: fb.firestore.FieldValue.serverTimestamp(),
      });

      batch.update(fb.usersCollection.doc(state.userProfile.id), {
        finishedSurvey: true,
        progress: 1,
        answers: null,
        answerId: answerRef.id,
      });

      commit('finishSurvey');

      await batch.commit();
    },
  },
});
