<template>
  <ValidationProvider
    tag="div"
    :rules="rules"
    :name="name || label"
    v-slot="{ errors, required }"
    class="input"
    :vid="vid"
  >
    <input
      :value="value"
      :id="id"
      :type="type"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
      class="field"
      :class="{ 'field--invalid': errors.length }"
    />

    <label
      v-if="label"
      :for="id"
      class="label"
      :class="{ 'label--invalid': errors.length }"
    >
      <span>{{ label || name }}</span>
      <span>{{ required ? " *" : "" }}</span>
    </label>

    <span class="error">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'CustomInput',

  components: { ValidationProvider },

  props: {
    value: {
      default: '',
      type: String,
    },

    rules: {
      default: '',
      type: [String, Object],
    },

    name: {
      type: String,
      default: '',
    },

    type: {
      type: String,
      default: 'text',
    },

    vid: String,

    label: String,
    placeholder: String,
    id: String,
  },
};
</script>

<style scoped>
.input {
  position: relative;
}

.label {
  position: absolute;
  top: calc(0.75rem / -2);
  left: var(--lengthMd1);
  padding: 2px 4px;
  background-color: white;
  font-size: 0.75rem;
  line-height: 100%;
}

.field:not(.field--invalid):focus ~ .label {
  color: var(--mainColor1);
}

.label--invalid,
.error {
  color: var(--dangerColor);
}

.error {
  margin-top: var(--lengthSm1);
  font-size: 0.75rem;
}

.field {
  width: 100%;
  padding: var(--lengthMd1) var(--lengthSm2) var(--lengthSm3);
  color: var(--fontColor1);
  border: 1px solid var(--grayColor3);
  border-radius: var(--lengthSm1);
  transition: border-color 300ms;
}

.field:not(.field--invalid):focus {
  border-color: var(--mainColor1);
}

.field--invalid {
  border-color: var(--dangerColor);
}
</style>
