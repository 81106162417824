import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyDU5cotmwj8dXwibkKclpigq47SEkLD4Ls',
  authDomain: 'ella-project-d7c57.firebaseapp.com',
  projectId: 'ella-project-d7c57',
  storageBucket: 'ella-project-d7c57.appspot.com',
  messagingSenderId: '805227821596',
  appId: '1:805227821596:web:6389ff543e441484f37dd9',
  measurementId: 'G-VMNFG11GT7',
};
firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
const auth = firebase.auth();
const { firestore } = firebase;

// collection references
const usersCollection = db.collection('users');

const companiesCollection = db.collection('companies');

const surveysCollection = db.collection('surveys');

const sectionsCollection = (surveyId) => surveysCollection.doc(surveyId).collection('sections');

const answersCollection = (surveyId) => surveysCollection.doc(surveyId).collection('answers');

const filtersCollection = (surveyId) => surveysCollection.doc(surveyId).collection('filters');

const questionsCollection = (surveyId) => surveysCollection.doc(surveyId).collection('questions');

const scaleCollection = (surveyId) => surveysCollection.doc(surveyId).collection('scale');

// export utils/refs
export {
  db,
  auth,
  firestore,
  usersCollection,
  companiesCollection,
  surveysCollection,
  sectionsCollection,
  answersCollection,
  filtersCollection,
  questionsCollection,
  scaleCollection,
};
