<template>
  <ValidationObserver ref="form" tag="div" v-slot="{ handleSubmit }" class="container">
    <div class="image">
      <div class="image__mask"></div>
    </div>
    <form @submit.prevent="handleSubmit(onSubmit)" class="form">
      <logo />

      <custom-input
        vid="user"
        v-model="user"
        type="text"
        label="Usuario"
        rules="required"
      />

      <custom-input
        vid="password"
        v-model="password"
        type="password"
        label="Contraseña"
        rules="required"
      />

      <custom-button type="submit" variant="primary" :disabled="isLoading">
        {{ isLoading ? 'Ingresando...' : 'Ingresar' }}
      </custom-button>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import { ValidationObserver } from 'vee-validate';

import { auth } from '../firebase';

import CustomButton from '../components/CustomButton.vue';
import CustomInput from '../components/CustomInput.vue';
import Logo from '../components/Logo.vue';

export default {
  name: 'Login',

  components: {
    ValidationObserver,
    CustomButton,
    CustomInput,
    Logo,
  },

  data: () => ({
    user: '',
    password: '',
    isLoading: false,
  }),

  computed: {
    ...mapState(['userProfile']),
  },

  methods: {
    ...mapActions(['login']),

    async onSubmit() {
      try {
        this.isLoading = true;

        await this.login({
          user: `${this.user}@ella.com`,
          password: this.password,
        });
      } catch (err) {
        this.setErrorMessage(err);
      } finally {
        this.isLoading = false;
      }
    },

    setErrorMessage(error) {
      switch (error.code) {
        case 'auth/wrong-password':
          this.$refs.form.setErrors({
            password: ['La contraseña ingresada es incorrecta.'],
          });
          break;

        case 'auth/user-not-found':
          this.$refs.form.setErrors({
            user: ['El usuario ingresado no existe.'],
          });
          break;

        default:
          this.$refs.form.setErrors({
            password: ['Ocurrio un error, por favor inténtalo de nuevo.'],
          });
          break;
      }
    },
  },

  beforeRouteEnter(to, from, next) {
    next(() => {
      if (auth.currentUser) next('/');
      else next();
    });
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--lengthMd1);
}

.input {
  width: 100%;
}

.logo-container {
  margin-bottom: var(--lengthLg2);
}

.input:last-of-type {
  margin-top: var(--lengthMd3);
  margin-bottom: var(--lengthLg2);
}

.image {
  display: none;
  background-image: url("../assets/login.jpg");
  background-position: center;
  background-size: cover;
}

.image__mask {
  width: 100%;
  height: 100%;
  background-color: rgba(000, 000, 000, .5);
}

@media only screen and (min-width: 768px) {
  .image {
    display: block;
    flex-basis: 50%;
  }

  .container {
    flex-direction: row;
  }

  .form {
    flex-basis: 50%;
    padding: var(--lengthMd2);
  }
}

@media only screen and (min-width: 1024px) {
  .form {
    flex-basis: 40%;
    padding: var(--lengthLg2);
  }

  .image {
    flex-basis: 60%;
  }
}
</style>
