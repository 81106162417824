import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';

import { auth } from '../firebase';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Survey',
    component: () => import('../views/Survey.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/welcome',
    name: 'WelcomeMessage',
    component: () => import('../views/WelcomeMessage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/thank-you',
    name: 'ThankYouMessage',
    component: () => import('../views/ThankYouMessage.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/no-survey',
    name: 'NoSurvey',
    component: () => import('../views/NoSurvey.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);

  if (requiresAuth && !auth.currentUser) next('/login');
  else next();
});

export default router;
