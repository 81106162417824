<template>
  <button
    @click="$emit('click', $event)"
    :disabled="disabled"
    :class="btnVariant"
    class="btn"
  >
    <slot name="prepend-icon"></slot>
    <slot></slot>
    <slot name="append-icon"></slot>
  </button>
</template>

<script>
export default {
  name: 'CustomButton',

  props: {
    variant: String,
    disabled: Boolean,
  },

  computed: {
    btnVariant() {
      switch (this.variant) {
        case 'text':
          return 'btn--text';
        case 'secondary':
          return 'btn--secondary';
        case 'ghost':
          return 'btn--ghost';
        case 'danger':
          return 'btn--danger';
        default:
          return 'btn--primary';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  justify-content: center;
  grid-gap: var(--lengthSm2);
  gap: var(--lengthSm2);
  padding: .75rem var(--lengthSm3);
  font-weight: var(--semi-bold);
  border-radius: 4px;
  transition: transform 300ms;

  &:disabled {
    color: var(--fontColor3);
    background-color: var(--grayColor1);
    cursor: not-allowed;
  }

  &:active:not(:disabled) {
    transform: translateY(1px);
  }

  &:hover:not(:disabled):not(:active) {
    transform: translateY(-1px);
  }

  &--primary {
    color: white;
    background-color: var(--mainColor1);
    transition: background-color 300ms;

    &:hover:not(:disabled) {
      background-color: var(--mainColor2);
    }
  }

  &--secondary {
    color: var(--mainColor1);
    background-color: white;
    box-shadow: 0 0 1px 1px var(--grayColor2) inset;
  }

  &--ghost {
    color: var(--mainColor1);
    box-shadow: 0 0 0 1px currentColor inset;
  }

  &--danger {
    color: var(--dangerColor);
    box-shadow: 0 0 0 1px currentColor inset;
  }

  &--text {
    padding: var(--lengthSm1);
    color: var(--fontColor2);
    transition: color 300ms;

    &:hover:not(:disabled) {
      color: var(--fontColor1);
    }
  }
}
</style>
