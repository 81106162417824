<template>
  <transition name="toast">
    <div v-show="toast" class="toast-container">
      <button class="toast" @click="clearAlert">{{ toast }}</button>
    </div>
  </transition>
</template>

<script>
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'Toast',

  watch: {
    toast(val) {
      if (val) {
        setTimeout(() => this.clearAlert(), 5000);
      }
    },
  },

  computed: {
    ...mapState(['toast']),
  },

  methods: {
    ...mapMutations(['setToast']),

    clearAlert() {
      this.setToast('');
    },
  },
};
</script>

<style lang="scss" scoped>
.toast-container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--lengthMd1);
  z-index: 9998;
}

.toast {
  padding: var(--lengthSm3);
  color: white;
  font-weight: var(--medium);
  background-color: var(--fontColor1);
  border-radius: var(--lengthSm1);
}

.toast-enter,
.toast-leave-to {
  transform: translateY(-200px);
  opacity: 0;
}

.toast-enter-active,
.toast-leave-active {
  transition: transform 300ms, opacity 300ms;
}
</style>
