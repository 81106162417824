import { extend } from 'vee-validate';
import {
  required,
  oneOf,
  excluded,
  confirmed,
  min,
} from 'vee-validate/dist/rules';

extend('required', {
  ...required,

  validate(value) {
    switch (typeof value) {
      case 'object':
        return value.id !== '';
      case 'string':
        return value !== '';
      case 'number':
        return value >= 0 && !Number.isNaN(value);
      case 'boolean':
        return value;
      default:
        return value !== '';
    }
  },

  message: 'Este campo es obligatorio.',
});

extend('minmax', {
  validate(value, { minChar, maxChar }) {
    const { length } = value;

    return length >= minChar && length <= maxChar;
  },

  params: ['minChar', 'maxChar'],

  message: 'El campo {_field_} debe contener entre {minChar} y {maxChar} caracteres.',
});

extend('oneOf', {
  ...oneOf,

  message:
    'El campo {_field_} solo puede contener uno de los valores definidos.',
});

extend('min', {
  ...min,

  message: 'El campo {_field_} debe contener por lo menos {length} caracteres.',
});

extend('excluded', {
  ...excluded,

  message: 'Este valor ya fue utiizado.',
});

extend('confirmed', {
  ...confirmed,

  message: 'El campo {_field_} debe coincidir con el campo de confirmación.',
});
