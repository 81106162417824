import Vue from 'vue';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSave,
  faSignOutAlt,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faCheck,
  faTimes,
  faChevronRight,
  faChevronLeft,
  faPaperPlane,
  faBars,
  faArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import App from './App.vue';
import router from './router';
import store from './store';
import { auth } from './firebase';

import './validation';

import './styles.scss';

library.add(
  faSave,
  faSignOutAlt,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faCheck,
  faTimes,
  faChevronRight,
  faChevronLeft,
  faPaperPlane,
  faBars,
  faArrowRight,
);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

let app;
auth.onAuthStateChanged((user) => {
  if (user) store.dispatch('fetchInitialData', user.uid);

  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
