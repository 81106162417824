<template>
  <div class="loader" :class="getSizeClass"></div>
</template>

<script>
export default {
  name: 'LoadingSpinner',

  props: {
    size: String,
  },

  computed: {
    getSizeClass() {
      switch (this.size) {
        case 'sm':
          return 'loader--sm';
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped>
.loader {
  border: var(--lengthSm1) solid transparent;
  border-top: var(--lengthSm1) solid var(--mainColor1);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
}

.loader--sm {
  border-width: 2px;
  border-top-width: 2px;
  width: 0.875rem;
  height: 0.875rem;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
